import React from "react";
import withRoot from "withRoot";
import PropTypes from "prop-types";
// @material-ui components
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grow from "@material-ui/core/Grow";
import Slide from "@material-ui/core/Slide";
// custom components
import MainLayout from "layout/MainLayout.jsx";
// custom icons
import LogoIcon from "components/Icons/Logo.jsx";
// jss
import styles from "assets/jss/views/styleHomePage.jsx";

class HomePageVi extends React.Component {
  constructor(props) {
    super(props);
  }

  render = () => {
    const { classes } = this.props;

    return (
      <MainLayout>
        <div>
          <Grow in={true}>
            <LogoIcon className={classes.mainLogo} />
          </Grow>
          <div>
            <div className={classes.textArea}>
              <Slide direction="right" in={true}>
                <Typography variant="h2">
                  Ai thực sự chiến thắng cuộc đua?
                </Typography>
              </Slide>

              {/* <Slide direction="right" in={true}>
                <Typography variant="h4">
                  Để trở thành người chiến thắng với khách hàng.
                </Typography>
              </Slide> */}
            </div>
          </div>
        </div>
      </MainLayout>
    );
  };
}

HomePageVi.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRoot(withStyles(styles)(HomePageVi));
