import React from "react";
import withRoot from "withRoot";
import PropTypes from "prop-types";
// @material-ui components
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Zoom from "@material-ui/core/Zoom";
import Grow from "@material-ui/core/Grow";
import Slide from "@material-ui/core/Slide";
// custom components
import MainLayout from "layout/MainLayout.jsx";
// custom icons
import LogoIcon from "components/Icons/Logo.jsx";
// assets
import careerBanner from "assets/img/career.png";
// jss
import styles from "assets/jss/views/styleCareerPage.jsx";

class CareerPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render = () => {
    const { classes } = this.props;

    return (
      <MainLayout>
        <div>
          <Grow in={true}>
            <LogoIcon className={classes.mainLogo} />
          </Grow>
          <div className={classes.rowHeader}>
            <Zoom in={true}>
              <div className={classes.textArea}>
                <Typography variant="h3">ABOUT</Typography>
                <Typography variant="h3" gutterBottom={true}>
                  {`${"　 "} BPMS Career`}
                </Typography>
              </div>
            </Zoom>
          </div>

          <div className={classes.rowContent}>
            <Slide direction="right" in={true}>
              <Typography variant="h6" className={classes.textCotent}>
                BPMSでは常に優秀な人材を募集しています。様々な能力を持ったスタッフが自己の能力を高め、
                お客様に最高のサービスを提供するために日々切磋琢磨しています。
                <br />
                語学力（日本語、英語、ベトナム語）やITの技術力を持った方はグローバルに活躍をするチャン
                スがあります。皆様の応募をお待ちしています。
              </Typography>
            </Slide>
            {/* <Grow in={true} style={{ transformOrigin: "0 0 0" }} timeout={1000}>
              <img style={{ marginLeft: 75 }} src={careerBanner} />
            </Grow> */}
          </div>

          <Button
            variant="contained"
            className={classes.btnApply}
            color="secondary"
          >
            応募・問い合わせ
          </Button>
        </div>
      </MainLayout>
    );
  };
}

CareerPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRoot(withStyles(styles)(CareerPage));
