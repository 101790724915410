const styles = () => ({
  mainLogo: {
    position: "absolute",
    top: 10,
    right: 10,
    height: 400
  },
  textArea: {
    margin: "0px auto",
    paddingTop: "15vh",
    "@media (min-width: 768px)": {
      width: "750px"
    },
    "@media (min-width: 992px)": {
      width: "970px"
    },
    "@media (min-width: 1200px)": {
      width: "1170px"
    }
  }
});

export default styles;
