import HomePage from "views/HomePage/HomePage.jsx";
import CompanyPage from "views/CompanyPage/CompanyPage.jsx";
import ServicePage from "views/ServicePage/ServicePage.jsx";
import CareerPage from "views/CareerPage/CareerPage.jsx";
import Contactpage from "views/ContactPage/ContactPage.jsx";
import PresidentMessage from "views/PresidentMessage/PresidentMessage.jsx";
// vi
import HomePageVi from "views/HomePage/HomePageVi.jsx";
import CompanyPageVi from "views/CompanyPage/CompanyPageVi.jsx";
import ServicePageVi from "views/ServicePage/ServicePageVi.jsx";
import CareerPageVi from "views/CareerPage/CareerPageVi.jsx";
import ContactpageVi from "views/ContactPage/ContactPageVi.jsx";
import PresidentMessageVi from "views/PresidentMessage/PresidentMessageVi.jsx";

var indexRoutes = [
  {
    path: "/vi/president-message",
    name: "President Message Vi",
    component: PresidentMessageVi,
    protected: false
  },
  {
    path: "/president-message",
    name: "President Message",
    component: PresidentMessage,
    protected: false
  },
  {
    path: "/vi/company",
    name: "CompanyPage",
    component: CompanyPageVi,
    protected: false
  },
  {
    path: "/company",
    name: "CompanyPage",
    component: CompanyPage,
    protected: false
  },
  {
    path: "/vi/contact",
    name: "ContactPage",
    component: ContactpageVi,
    protected: false
  },
  {
    path: "/contact",
    name: "ContactPage",
    component: Contactpage,
    protected: false
  },
  {
    path: "/vi/service",
    name: "ServicePage",
    component: ServicePageVi,
    protected: false
  },
  {
    path: "/service",
    name: "ServicePage",
    component: ServicePage,
    protected: false
  },
  {
    path: "/vi/career",
    name: "CareerPage",
    component: CareerPageVi,
    protected: false
  },
  {
    path: "/career",
    name: "CareerPage",
    component: CareerPage,
    protected: false
  },
  {
    path: "/vi",
    name: "HomePage",
    component: HomePageVi,
    protected: false
  },
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
    protected: false
  }
];

export default indexRoutes;
