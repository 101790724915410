import React from "react";
import withRoot from "withRoot";
import PropTypes from "prop-types";
// @material-ui components
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Zoom from "@material-ui/core/Zoom";
import Grow from "@material-ui/core/Grow";
import Slide from "@material-ui/core/Slide";
// custom components
import MainLayout from "layout/MainLayout.jsx";
// custom icons
import LogoIcon from "components/Icons/Logo.jsx";
// jss
import styles from "assets/jss/views/stylePresidentMessage.jsx";

class PresidentMessage extends React.Component {
  constructor(props) {
    super(props);
  }

  render = () => {
    const { classes } = this.props;

    return (
      <MainLayout>
        <div>
          <Grow in={true}>
            <LogoIcon className={classes.mainLogo} />
          </Grow>
          <div className={classes.rowHeader}>
            <Zoom in={true}>
              <div className={classes.textArea}>
                <Typography variant="h3" style={{ color: "#fff" }}>
                  President
                </Typography>
                <Typography
                  variant="h3"
                  style={{ color: "#fff" }}
                  gutterBottom={true}
                >
                  {`${"　 　 　"} Message`}
                </Typography>
              </div>
            </Zoom>
          </div>

          <div className={classes.rowContent}>
            <Slide direction="right" in={true}>
              <Typography
                variant="h6"
                align="justify"
                className={classes.textCotent}
              >
                平素より、BPMSのサービスをご愛顧いただいておりますお客様、株主の皆様、ビジネスパートナーの皆様のご支援を賜り、誠にありがとうございます。
                <br />
                <br />
                1986年に始まったドイモイ政策によりベトナム経済は目覚ましい発展を続けています。日本との関わりも強く、多くの企業がベトナムに進出をしています。私自身も日本の企業で10年間働き、日本の技術力、ビジネス手法、勤勉さを学びました。
                <br />
                <br />
                この経験を生かして2018年7月にBPMSを創立し、日本のお客様向けにBPOサービスの提供を開始しています。単なるアウトソースではなくプロセス全体の作業を受託し、日本式の管理手法、品質管理を行いながらサービスを提供しています。
                <br />
                <br />
                日本では人材不足が深刻化していますが、その解決の一役を担うことが私たちの使命だと考えています。お客様がライバルたち競争に勝ち抜くために、BPMSは全力を尽くします。
              </Typography>
            </Slide>

            <Typography
              variant="h6"
              className={classes.textCotent}
              style={{ textAlign: "right" }}
            >
              BPMS　代表取締役社長
            </Typography>
            <Typography
              variant="h3"
              className={classes.textCotent}
              style={{ textAlign: "right" }}
            >
              Ho Cam Tu
            </Typography>
          </div>
        </div>
      </MainLayout>
    );
  };
}

PresidentMessage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRoot(withStyles(styles)(PresidentMessage));
