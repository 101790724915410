import React from "react";
import withRoot from "withRoot";
import PropTypes from "prop-types";
// @material-ui components
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import Grow from "@material-ui/core/Grow";
import Slide from "@material-ui/core/Slide";
// custom components
import MainLayout from "layout/MainLayout.jsx";
// custom icons
import LogoIcon from "components/Icons/Logo.jsx";
// jss
import styles from "assets/jss/views/styleCompanyPage.jsx";

class CompanyPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render = () => {
    const { classes } = this.props;

    return (
      <MainLayout>
        <div>
          <Grow in={true}>
            <LogoIcon className={classes.mainLogo} />
          </Grow>
          <div className={classes.rowHeader}>
            <div className={classes.textArea}>
              <Zoom in={true}>
                <Typography variant="h3">ABOUT</Typography>
              </Zoom>

              <Zoom in={true}>
                <Typography variant="h3" gutterBottom={true}>
                  {`${"　 　"} BPMS Viet Nam`}
                </Typography>
              </Zoom>
            </div>
          </div>

          <div className={classes.rowContent}>
            <Slide direction="right" in={true}>
              <Typography variant="h4">
                Cong Ty BPMS VIET NAM Overviews
              </Typography>
            </Slide>

            <Slide direction="left" in={true}>
              <div>
                <Typography variant="h6" gutterBottom={true}>
                  Company Name : BPMS Viet Nam Co., LTD
                </Typography>
                <Typography variant="h6" gutterBottom={true}>
                  President : Ho Cam Tu
                </Typography>
                <Typography variant="h6" gutterBottom={true}>
                  Head Office: : Floor 4, Room 403, No. 145-156 HKL building
                  ,Nguyen Huu Tho street, Phuoc Kien, <br /> 　　　　　　 Nha Be
                  district, Ho Chi Minh city, Viet Nam
                </Typography>
                <Typography variant="h6" gutterBottom={true}>
                  Main Business : BPO, IT Development, RPA, etc
                </Typography>
              </div>
            </Slide>
          </div>
        </div>
      </MainLayout>
    );
  };
}

CompanyPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRoot(withStyles(styles)(CompanyPage));
