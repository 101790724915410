const styles = () => ({
  mainLogo: {
    position: "absolute",
    top: 10,
    right: 10,
    height: 400
  },
  rowHeader: {
    padding: "40px 0px",
    backgroundColor: "#000",
    backgroundImage: "linear-gradient(to right, white, #000)"
  },
  textArea: {
    "& h3": {
      fontWeight: "bold",
      paddingLeft: "25%"
    },
    margin: "0px auto",
    "@media (min-width: 768px)": {
      width: "750px"
    },
    "@media (min-width: 992px)": {
      width: "970px"
    },
    "@media (min-width: 1200px)": {
      width: "1170px"
    }
  },
  rowContent: {
    margin: "0px auto",
    paddingTop: 20,
    "@media (min-width: 768px)": {
      width: "750px"
    },
    "@media (min-width: 992px)": {
      width: "970px"
    },
    "@media (min-width: 1200px)": {
      width: "1170px"
    }
  },
  textCotent: {
    width: "70%"
  }
});

export default styles;
