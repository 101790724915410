import React from "react";
import withRoot from "withRoot";
import PropTypes from "prop-types";
// @material-ui components
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Zoom from "@material-ui/core/Zoom";
import Grow from "@material-ui/core/Grow";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
// custom components
import MainLayout from "layout/MainLayout.jsx";
// custom icons
import LogoIcon from "components/Icons/Logo.jsx";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
// assets
// import careerBanner from "assets/img/career.png";
// jss
import styles from "assets/jss/views/styleCareerPage.jsx";
import { Divider } from "@material-ui/core";

class CareerPageVi extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  render = () => {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <MainLayout>
          <div>
            <Grow in={true}>
              <LogoIcon className={classes.mainLogo} />
            </Grow>
            <div className={classes.rowHeader}>
              <Zoom in={true}>
                <div className={classes.textArea}>
                  <Typography variant="h3">TUYỂN DỤNG</Typography>
                </div>
              </Zoom>
            </div>

            <div className={classes.rowContent}>
              <Slide direction="right" in={true}>
                <Typography variant="h6" className={classes.textCotent}>
                  Công ty của chúng tôi luôn tìm kiếm những tài năng xuất sắc.
                  Với đội ngũ nhân viên nhiều năng lực, luôn nâng cao, trao dồi
                  bản thân nhằm mang đến cho khách hàng những dịch vụ tốt nhất{" "}
                  <br />
                  Những ứng viên có khả năng về ngôn ngữ (Nhật, Anh, Việt), hoặc
                  về lĩnh vực IT sẽ có cơ hội đóng vai trò tích cực trên toàn
                  cầu. Chúng tôi luôn chào đón sự gia nhập của các bạn.
                </Typography>
              </Slide>
              {/* <Grow
                in={true}
                style={{ transformOrigin: "0 0 0" }}
                timeout={1000}
              >
                <img style={{ marginLeft: 75 }} src={careerBanner} />
              </Grow> */}
            </div>

            <Button
              variant="contained"
              className={classes.btnApply}
              color="secondary"
              onClick={() => this.setState({ open: true })}
            >
              Ứng Tuyển
            </Button>
          </div>
        </MainLayout>

        <Dialog open={this.state.open} onClose={this.handleClose}>
          <DialogTitle>Liên hệ ứng tuyển</DialogTitle>
          <DialogContent>
            <Typography color="inherit">
              Ứng cử viên vui lòng gửi thông tin ứng tuyển về thông tin sau
            </Typography>
            <Divider style={{ marginBottom: 15 }} />
            <Typography color="inherit">
              <PhoneIcon style={{ fontSize: "17px" }} /> 0834500634(C.Tu)
            </Typography>
            <Typography color="inherit">
              <EmailIcon style={{ fontSize: "17px" }} /> bpmsvietnam@gmail.com
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="secondary" autoFocus>
              Đóng
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  };
}

CareerPageVi.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRoot(withStyles(styles)(CareerPageVi));
