import React from "react";
import withRoot from "withRoot";
import PropTypes from "prop-types";
// @material-ui components
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import Grow from "@material-ui/core/Grow";
import Slide from "@material-ui/core/Slide";
// custom components
import MainLayout from "layout/MainLayout.jsx";
// custom icons
import LogoIcon from "components/Icons/Logo.jsx";
// jss
import styles from "assets/jss/views/styleContactPage.jsx";

class ContactPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render = () => {
    const { classes } = this.props;

    return (
      <MainLayout>
        <div>
          <Grow in={true}>
            <LogoIcon className={classes.mainLogo} />
          </Grow>
          <div className={classes.rowHeader}>
            <Zoom in={true}>
              <div className={classes.textArea}>
                <Typography variant="h3" style={{ color: "#ffffff" }}>
                  About Contact
                </Typography>
              </div>
            </Zoom>
          </div>

          <Slide direction="left" in={true}>
            <div className={classes.rowContent}>
              <Typography variant="h4">
                BPMS VIET NAM COMPANY LIMITED
              </Typography>
              <Typography variant="h6">
                Floor 4 Room 403 No.154-156 HKL building,Nguyen Huu Tho street,
                <br />
                Phuoc Kien Commune, Nha Be District, Ho Chi Minh City, Viet Nam
              </Typography>
              <Typography variant="h6">Tel: 0834500634</Typography>
              <Typography variant="h6">
                E-mail: bpmsvietnam@gmail.com
              </Typography>
              <a href="https://goo.gl/maps/NUqDE73m3Jq" target="_blank">
                View Map
              </a>
            </div>
          </Slide>
        </div>
      </MainLayout>
    );
  };
}

ContactPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRoot(withStyles(styles)(ContactPage));
