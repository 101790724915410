const styles = () => ({
  mainLogo: {
    position: "absolute",
    top: 10,
    right: 10,
    height: 400
  },
  rowHeader: {
    padding: "40px 0px",
    backgroundColor: "#d9d9d9",
    backgroundImage: "linear-gradient(to right, white, #d9d9d9)"
  },
  textArea: {
    "& h3": {
      fontWeight: "bold",
      paddingLeft: "25%"
    },
    margin: "0px auto",
    "@media (min-width: 768px)": {
      width: "750px"
    },
    "@media (min-width: 992px)": {
      width: "970px"
    },
    "@media (min-width: 1200px)": {
      width: "1170px"
    }
  },
  rowContent: {
    margin: "0px auto",
    paddingTop: 20,
    "@media (min-width: 768px)": {
      width: "750px"
    },
    "@media (min-width: 992px)": {
      width: "970px"
    },
    "@media (min-width: 1200px)": {
      width: "1170px"
    }
  },
  textCotent: {
    width: "70%",
    lineHeight: "40px",
    minHeight: "280px"
  },
  btnApply: {
    position: "absolute",
    right: 177,
    // bottom: 55,
    bottom: 0,
    padding: "10px 25px"
  }
});

export default styles;
