import React from "react";
import withRoot from "withRoot";
import PropTypes from "prop-types";
// @material-ui components
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Zoom from "@material-ui/core/Zoom";
import Grow from "@material-ui/core/Grow";
import Slide from "@material-ui/core/Slide";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
// custom components
import MainLayout from "layout/MainLayout.jsx";
// custom icons
import LogoIcon from "components/Icons/Logo.jsx";
// jss
import styles from "assets/jss/views/styleServicePage.jsx";

class ServicePageVi extends React.Component {
  constructor(props) {
    super(props);
  }

  render = () => {
    const { classes } = this.props;

    return (
      <MainLayout>
        <div>
          <Grow in={true}>
            <LogoIcon className={classes.mainLogo} />
          </Grow>
          <div className={classes.rowHeader}>
            <div className={classes.textArea}>
              <Zoom in={true}>
                <Typography variant="h3" style={{ paddingLeft: "0" }}>
                  DANH MỤC DỊCH VỤ
                </Typography>
              </Zoom>

              <Zoom in={true}>
                <Typography variant="h3" gutterBottom={true}>
                  {`${"　 "} BPMS`}
                </Typography>
              </Zoom>
            </div>
          </div>

          <div className={classes.rowContent}>
            <div className={classes.leftCol}>
              {/* Service Content */}
              <Grow
                in={true}
                style={{ transformOrigin: "0 0 0" }}
                timeout={1000}
              >
                <div className={classes.serviceContent}>
                  <div className={classes.serviceCol}>
                    <Card className={classes.card}>
                      <CardContent>
                        <Typography variant="h5">BPO</Typography>
                        <Typography variant="caption">
                          Business Process Outsourcing
                        </Typography>
                      </CardContent>
                    </Card>
                    <Typography variant="body2" className={classes.serviceDesc}>
                      Không chỉ là nhập liệu đơn thuần, chúng tôi còn là đơn vị
                      sử lý quy trình công việc của khách hàng
                    </Typography>
                  </div>

                  <div className={classes.serviceCol}>
                    <Card className={classes.card}>
                      <CardContent>
                        <Typography variant="h5">RPA-BPO</Typography>
                        <Typography variant="caption">
                          Robotic Business Process Automation & Outsourcing
                        </Typography>
                      </CardContent>
                    </Card>
                    <Typography variant="body2" className={classes.serviceDesc}>
                      Đây là một dịch vụ kết hợp RPA vào BPO trên quy trình công
                      việc của khách hàng
                    </Typography>
                  </div>

                  <div className={classes.serviceCol}>
                    <Card className={classes.card}>
                      <CardContent>
                        <Typography variant="h5">UNIT BPO</Typography>
                        <Typography variant="caption">
                          Unit Rental for Business Process Outsourcing
                        </Typography>
                      </CardContent>
                    </Card>
                    <Typography variant="body2" className={classes.serviceDesc}>
                      Đây là một dịch vụ cung cấp các nguồn cần thiết cho BPO.
                      Hướng đến các doanh nghiệp tự quản
                    </Typography>
                  </div>
                </div>
              </Grow>
              {/* End Service Content */}

              {/* Service Content */}
              <Grow
                in={true}
                style={{ transformOrigin: "0 0 0" }}
                timeout={1000}
              >
                <div className={classes.serviceContent}>
                  <div className={classes.serviceCol}>
                    <Card className={classes.card}>
                      <CardContent>
                        <Typography variant="h5">BPO CUSTOM</Typography>
                        <Typography variant="caption">
                          Business Process Outsourcing
                        </Typography>
                      </CardContent>
                    </Card>
                    <Typography variant="body2" className={classes.serviceDesc}>
                      Tùy chỉnh BPO theo mong muốn của khách hàng.
                    </Typography>
                  </div>

                  <div className={classes.serviceCol}>
                    <Card className={classes.card}>
                      <CardContent>
                        <Typography variant="h5">Biz support</Typography>
                        <Typography variant="caption">in Vietnam</Typography>
                      </CardContent>
                    </Card>
                    <Typography variant="body2" className={classes.serviceDesc}>
                      Chúng tôi sẽ hỗ trợ chuyến thăm của bạn đến Việt Nam.
                    </Typography>
                  </div>

                  <div className={classes.serviceCol}>
                    <Card className={classes.card}>
                      <CardContent>
                        <Typography variant="h5">Biz Matching</Typography>
                        <Typography variant="caption">
                          Business Process Outsourcing
                        </Typography>
                      </CardContent>
                    </Card>
                    <Typography variant="body2" className={classes.serviceDesc}>
                      Chúng tôi hỗ trợ kết hợp kinh doanh tại Việt Nam.
                    </Typography>
                  </div>
                </div>
              </Grow>
              {/* End Service Content */}
            </div>

            {/* Right Col */}
            <div className={classes.rightCol}>
              <Slide direction="left" in={true}>
                <Typography
                  variant="h6"
                  style={{ fontSize: "1rem", textAlign: "left" }}
                >
                  Chúng tôi cung cấp các dịch vụ BPO, phát triển CNTT, RPA tập
                  trung chủ yếu vào khách hàng Nhật Bản. Khác với cách gia công
                  đơn giản từ trước đến nay chúng tôi sẽ tiến hành hệ thống hóa,
                  hiệu suất hóa đồng thời với việc tiêu chuẩn hóa quy trình công
                  việc
                  <br />
                  Chúng tôi sẽ cung cấp các giải pháp khác nhau như: cho thuê
                  đơn vị theo nhu cầu của khách hàng, vì vậy xin vui lòng liên
                  hệ với chúng tôi.
                </Typography>
              </Slide>
              <Button
                variant="contained"
                className={classes.btnApply}
                color="secondary"
              >
                Trao đổi
              </Button>
            </div>
            {/* End Right Col */}
          </div>
        </div>
      </MainLayout>
    );
  };
}

ServicePageVi.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRoot(withStyles(styles)(ServicePageVi));
