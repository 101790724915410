import React from "react";
import withRoot from "withRoot";
import PropTypes from "prop-types";
// @material-ui components
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import Grow from "@material-ui/core/Grow";
import Slide from "@material-ui/core/Slide";
// custom components
import MainLayout from "layout/MainLayout.jsx";
// custom icons
import LogoIcon from "components/Icons/Logo.jsx";
// jss
import styles from "assets/jss/views/styleCompanyPage.jsx";

class CompanyPageVi extends React.Component {
  constructor(props) {
    super(props);
  }

  render = () => {
    const { classes } = this.props;

    return (
      <MainLayout>
        <div>
          <Grow in={true}>
            <LogoIcon className={classes.mainLogo} />
          </Grow>
          <div className={classes.rowHeader}>
            <Zoom in={true}>
              <div className={classes.textArea}>
                <Typography variant="h3" style={{ paddingLeft: "15%" }}>
                  TỔNG QUAN
                </Typography>
                <Typography
                  variant="h3"
                  gutterBottom={true}
                  style={{ paddingLeft: "15%" }}
                >
                  {`${"　 　 　 　 "} BPMS Viet Nam`}
                </Typography>
              </div>
            </Zoom>
          </div>

          <div className={classes.rowContent}>
            <Slide direction="left" in={true}>
              <div>
                <Typography variant="h6" gutterBottom={true}>
                  TÊN CÔNG TY : <strong>TNHH BPMS VIET NAM</strong>
                </Typography>
                <Typography variant="h6" gutterBottom={true}>
                  Đại diện : Hồ Cẩm Tú
                </Typography>
                <Typography variant="h6" gutterBottom={true}>
                  Địa chỉ: Lầu 4, Phòng 403 số 154-156 Nguyễn Hữu Thọ , xã Phước
                  Kiển, <br /> 　　　Huyện Nhà Bè,Thành Phố Hồ Chí Minh
                </Typography>
                <Typography variant="h6" gutterBottom={true}>
                  Lĩnh vực kinh doanh : BPO, IT, RPA, …
                </Typography>
              </div>
            </Slide>
          </div>
        </div>
      </MainLayout>
    );
  };
}

CompanyPageVi.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRoot(withStyles(styles)(CompanyPageVi));
