import React from "react";
import withRoot from "withRoot";
import PropTypes from "prop-types";
// @material-ui components
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import Grow from "@material-ui/core/Grow";
import Slide from "@material-ui/core/Slide";
// custom components
import MainLayout from "layout/MainLayout.jsx";
// custom icons
import LogoIcon from "components/Icons/Logo.jsx";
// jss
import styles from "assets/jss/views/stylePresidentMessage.jsx";

class PresidentMessageVi extends React.Component {
  constructor(props) {
    super(props);
  }

  render = () => {
    const { classes } = this.props;

    return (
      <MainLayout>
        <div>
          <Grow in={true}>
            <LogoIcon className={classes.mainLogo} />
          </Grow>
          <div className={classes.rowHeader}>
            <Zoom in={true}>
              <div className={classes.textArea}>
                <Typography variant="h3" style={{ color: "#fff" }}>
                  THÔNG ĐIỆP
                </Typography>
              </div>
            </Zoom>
          </div>

          <div className={classes.rowContent}>
            <Slide direction="right" in={true}>
              <Typography
                variant="h6"
                align="justify"
                className={classes.textCotent}
              >
                Công ty chúng tôi xin gửi lời cảm ơn chân thành đến quý khách
                hàng đã tin tưởng và hợp tác với chúng tôi trong thời gian qua.
                <br />
                <br />
                Với chính sách đổi mới bắt đầu từ năm 1986, nền kinh tế Việt Nam
                đã đạt được những tiến bộ rõ rệt. Quan hệ với Nhật Bản cũng bền
                chặt, và nhiều công ty Nhật Bản đang vào Việt Nam. Bản thân tôi
                đã làm việc tại các công ty Nhật Bản trong mười năm, và đã học
                hỏi công nghệ, phương pháp kinh doanh và đặc biệt là sự siêng
                năng của người Nhật .
                <br />
                <br />
                Tận dụng trải nghiệm này, chúng tôi đã thành lập BPMS vào tháng
                7 năm 2018 và bắt đầu cung cấp dịch vụ BPO cho khách hàng Nhật
                Bản. Không chỉ đơn thuần là nhập dữ liệu mà chúng tôi sẽ cung
                cấp dịch vụ vừa có thể ủy thác toàn bộ quy trình công việc vừa
                quản lý chất lượng theo phương pháp quản lý của Nhật Bản .
                <br />
                <br />Ở Nhật Bản, tình trạng thiếu nguồn nhân lực ngày càng trầm
                trọng , chúng tôi nghĩ rằng nhiệm vụ của chúng tôi là góp phần
                giải quyết chúng. BPMS sẽ làm hết sức mình để khách hàng có thể
                yên tâm canh tranh với các đối thủ trong thời đại công nghệ
              </Typography>
            </Slide>
            <Typography
              variant="h6"
              className={classes.textCotent}
              style={{ textAlign: "right" }}
            >
              Giám đốc đại diện BPMS
            </Typography>
            <Typography
              variant="h3"
              className={classes.textCotent}
              style={{ textAlign: "right" }}
            >
              Hồ Cẩm Tú
            </Typography>
          </div>
        </div>
      </MainLayout>
    );
  };
}

PresidentMessageVi.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRoot(withStyles(styles)(PresidentMessageVi));
