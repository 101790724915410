import React from "react";
import withRoot from "withRoot";
import PropTypes from "prop-types";
// @material-ui components
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import Grow from "@material-ui/core/Grow";
import Slide from "@material-ui/core/Slide";
// custom components
import MainLayout from "layout/MainLayout.jsx";
// custom icons
import LogoIcon from "components/Icons/Logo.jsx";
// jss
import styles from "assets/jss/views/styleContactPage.jsx";

class ContactPageVi extends React.Component {
  constructor(props) {
    super(props);
  }

  render = () => {
    const { classes } = this.props;

    return (
      <MainLayout>
        <div>
          <Grow in={true}>
            <LogoIcon className={classes.mainLogo} />
          </Grow>
          <div className={classes.rowHeader}>
            <Zoom in={true}>
              <div className={classes.textArea}>
                <Typography variant="h3" style={{ color: "#ffffff" }}>
                  LIÊN HỆ
                </Typography>
              </div>
            </Zoom>
          </div>

          <Slide direction="left" in={true}>
            <div className={classes.rowContent}>
              <Typography variant="h4">CÔNG TY TNHH BPMS VIET NAM</Typography>
              <Typography variant="h6">
                Lầu 4, Phòng 403 số 154-156 Nguyễn Hữu Thọ , xã Phước Kiển,
                <br />
                Huyện Nhà Bè,Thành Phố Hồ Chí Minh
              </Typography>
              <Typography variant="h6">Tel: 0834500634</Typography>
              <Typography variant="h6">
                E-mail: bpmsvietnam@gmail.com
              </Typography>
              <a href="https://goo.gl/maps/NUqDE73m3Jq" target="_blank">
                View Map
              </a>
            </div>
          </Slide>
        </div>
      </MainLayout>
    );
  };
}

ContactPageVi.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRoot(withStyles(styles)(ContactPageVi));
