import React from "react";
import withRoot from "withRoot";
import PropTypes from "prop-types";
// @material-ui components
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Zoom from "@material-ui/core/Zoom";
import Grow from "@material-ui/core/Grow";
import Slide from "@material-ui/core/Slide";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
// custom components
import MainLayout from "layout/MainLayout.jsx";
// custom icons
import LogoIcon from "components/Icons/Logo.jsx";
// jss
import styles from "assets/jss/views/styleServicePage.jsx";

class ServicePage extends React.Component {
  constructor(props) {
    super(props);
  }

  render = () => {
    const { classes } = this.props;

    return (
      <MainLayout>
        <div>
          <Grow in={true}>
            <LogoIcon className={classes.mainLogo} />
          </Grow>
          <div className={classes.rowHeader}>
            <div className={classes.textArea}>
              <Zoom in={true}>
                <Typography variant="h3">ABOUT</Typography>
              </Zoom>

              <Zoom in={true}>
                <Typography variant="h3" gutterBottom={true}>
                  {`${"　 "} BPMS Services`}
                </Typography>
              </Zoom>
            </div>
          </div>

          <div className={classes.rowContent}>
            <div className={classes.leftCol}>
              <Slide direction="right" in={true}>
                <Typography
                  variant="h5"
                  className={classes.textCotent}
                  style={{ fontWeight: "bold", textAlign: "center" }}
                >
                  BPMS サービス一覧
                </Typography>
              </Slide>

              {/* Service Content */}
              <Grow
                in={true}
                style={{ transformOrigin: "0 0 0" }}
                timeout={1000}
              >
                <div className={classes.serviceContent}>
                  <div className={classes.serviceCol}>
                    <Card className={classes.card}>
                      <CardContent>
                        <Typography variant="h5">BPO</Typography>
                        <Typography variant="caption">
                          Business Process Outsourcing
                        </Typography>
                      </CardContent>
                    </Card>
                    <Typography variant="body2" className={classes.serviceDesc}>
                      単なるアウトソースではなく、お客様の業務をプロセス単位で行うサービスです。
                    </Typography>
                  </div>

                  <div className={classes.serviceCol}>
                    <Card className={classes.card}>
                      <CardContent>
                        <Typography variant="h5">RPA-BPO</Typography>
                        <Typography variant="caption">
                          Robotic Business Process Automation & Outsourcing
                        </Typography>
                      </CardContent>
                    </Card>
                    <Typography variant="body2" className={classes.serviceDesc}>
                      BPOにRPAを組み入れて、お客様の業務をプロセス単位で行うサービスです。
                    </Typography>
                  </div>

                  <div className={classes.serviceCol}>
                    <Card className={classes.card}>
                      <CardContent>
                        <Typography variant="h5">UNIT BPO</Typography>
                        <Typography variant="caption">
                          Unit Rental for Business Process Outsourcing
                        </Typography>
                      </CardContent>
                    </Card>
                    <Typography variant="body2" className={classes.serviceDesc}>
                      BPOに必要なリソースを提供するサービスです。マネジメントは自社で行いたい企業向けです。
                    </Typography>
                  </div>
                </div>
              </Grow>
              {/* End Service Content */}

              {/* Service Content */}
              <Grow
                in={true}
                style={{ transformOrigin: "0 0 0" }}
                timeout={1000}
              >
                <div className={classes.serviceContent}>
                  <div className={classes.serviceCol}>
                    <Card className={classes.card}>
                      <CardContent>
                        <Typography variant="h5">BPO CUSTOM</Typography>
                        <Typography variant="caption">
                          Business Process Outsourcing
                        </Typography>
                      </CardContent>
                    </Card>
                    <Typography variant="body2" className={classes.serviceDesc}>
                      お客様のご希望に応じてBPOをカスタマイズします。
                    </Typography>
                  </div>

                  <div className={classes.serviceCol}>
                    <Card className={classes.card}>
                      <CardContent>
                        <Typography variant="h5">Biz support</Typography>
                        <Typography variant="caption">in Vietnam</Typography>
                      </CardContent>
                    </Card>
                    <Typography variant="body2" className={classes.serviceDesc}>
                      お客様のベトナム進出をサポートします。
                    </Typography>
                  </div>

                  <div className={classes.serviceCol}>
                    <Card className={classes.card}>
                      <CardContent>
                        <Typography variant="h5">Biz Matching</Typography>
                        <Typography variant="caption">
                          Business Process Outsourcing
                        </Typography>
                      </CardContent>
                    </Card>
                    <Typography variant="body2" className={classes.serviceDesc}>
                      ベトナムでのビジネスマッチングをサポートします。
                    </Typography>
                  </div>
                </div>
              </Grow>
              {/* End Service Content */}
            </div>

            {/* Right Col */}
            <div className={classes.rightCol}>
              <Slide direction="left" in={true}>
                <Typography variant="h6" style={{ textAlign: "left" }}>
                  私たちは日本のお客様を中心にBPOサービス、IT開発、RPAを
                  提供しています。単純なアウトソースとは一線を画し、業務プ
                  ロセスの標準化と同時に効率化、システム化を行います。
                  お客様のニーズに応じてユニットレンタルなど様々なソリュー
                  ションを提供致しますので、お気軽にお問い合わせください。
                </Typography>
              </Slide>
              <Button
                variant="contained"
                className={classes.btnApply}
                color="secondary"
              >
                応募・問い合わせ
              </Button>
            </div>
            {/* End Right Col */}
          </div>
        </div>
      </MainLayout>
    );
  };
}

ServicePage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRoot(withStyles(styles)(ServicePage));
