import React from "react";

class LogoIcon extends React.Component {
  render = () => {
    const { ...rest } = this.props;
    return (
      <svg
        {...rest}
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="373.000000pt"
        height="358.000000pt"
        viewBox="0 0 373.000000 358.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,358.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            fill="#0000cc"
            d="M1531 3112 c-173 -191 -316 -352 -318 -358 -5 -14 626 -709 642 -709
12 0 645 692 645 705 0 12 -631 705 -644 707 -6 1 -152 -154 -325 -345z"
          />
          <path
            fill="#9900ff"
            d="M601 2221 c-177 -192 -321 -353 -321 -356 0 -7 639 -705 645 -705 6
0 645 698 645 705 0 7 -639 705 -645 705 -2 0 -148 -157 -324 -349z"
          />
          <path
            fill="#9900ff"
            d="M2471 2221 c-177 -192 -321 -353 -321 -356 0 -7 639 -705 645 -705 6
0 645 698 645 705 0 7 -639 705 -645 705 -2 0 -148 -157 -324 -349z"
          />
          <path
            fill="#d9d9d9"
            d="M2099 2283 c-85 -92 -174 -190 -199 -216 l-46 -49 -193 212 c-106
116 -196 210 -201 208 -4 -2 -49 -52 -100 -111 l-92 -109 39 -41 c68 -72 258
-281 271 -298 11 -15 -4 -35 -111 -151 -68 -74 -127 -140 -131 -146 -7 -11
103 -152 124 -160 4 -2 94 92 200 208 105 115 193 210 195 210 2 0 92 -97 200
-216 159 -174 200 -213 209 -202 88 98 127 149 120 159 -4 7 -63 73 -131 147
-107 116 -122 136 -111 151 13 17 204 226 271 298 l40 42 -83 98 c-46 54 -91
106 -100 116 -17 18 -24 12 -171 -150z"
          />
          <path
            fill="#0000cc"
            d="M1531 1472 c-173 -191 -316 -352 -318 -358 -4 -13 623 -708 641 -711
11 -2 636 682 643 703 4 12 -623 708 -641 711 -6 1 -152 -154 -325 -345z"
          />
        </g>
      </svg>
    );
  };
}

export default LogoIcon;
