const styles = () => ({
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  areaLogo: {
    marginLeft: -12,
    marginRight: 20
  },
  menu: {
    marginLeft: "auto"
  },
  menuItem: {
    textTransform: "none",
    padding: "8px 30px"
  },
  logo: {
    height: 40,
    width: 40
  },
  main: {
    // minHeight: "calc(100vh - 64px)",
    marginTop: 10,
    position: "relative"
  },
  lang: {
    borderLeft: "1px solid #ccc",
    paddingLeft: 3,
    marginLeft: 5
  },
  langIcon: {
    // padding: 0
  },
  langImg: {
    margin: 10,
    height: 40,
    width: 40
  },
  langImgRoot: {
    margin: 0
  }
});

export default styles;
