const styles = () => ({
  mainLogo: {
    position: "absolute",
    top: 10,
    right: 10,
    height: 400
  },
  rowHeader: {
    padding: "40px 0px",
    backgroundColor: "#9900ff",
    backgroundImage: "linear-gradient(to right, white, #9900ff)"
  },
  textArea: {
    "& h3": {
      fontWeight: "bold",
      paddingLeft: "25%"
    },
    margin: "0px auto",
    "@media (min-width: 768px)": {
      width: "750px"
    },
    "@media (min-width: 992px)": {
      width: "970px"
    },
    "@media (min-width: 1200px)": {
      width: "1170px"
    }
  },
  rowContent: {
    margin: "0px auto",
    paddingTop: 20,
    display: "flex"
    // "@media (min-width: 768px)": {
    //   width: "750px"
    // },
    // "@media (min-width: 992px)": {
    //   width: "970px"
    // },
    // "@media (min-width: 1200px)": {
    //   width: "1170px"
    // }
  },
  leftCol: {
    width: "70%"
  },
  rightCol: {
    width: "30%",
    marginTop: 155,
    paddingRight: 40,
    textAlign: "center"
  },
  textCotent: {
    width: "91%",
    lineHeight: "50px"
  },
  btnApply: {
    margin: "5px 0px"
  },
  serviceContent: {
    width: "85%",
    margin: "0 auto",
    display: "flex"
  },
  serviceCol: {
    width: "30%"
  },
  serviceDesc: {
    margin: "0 12px"
  },
  card: {
    textAlign: "center",
    margin: 12,
    minHeight: 110,
    borderRadius: 20,
    border: "1px solid #9900ff"
  }
});

export default styles;
