import React from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
// @material-ui components
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
// custom Icons
import LogoIcon from "components/Icons/Logo.jsx";
// assets
import japanImg from "assets/img/japan.png";
import vietnamImg from "assets/img/vietnam.png";
// jss
import styles from "assets/jss/layout/styleMainLayout.jsx";

class MainLayout extends React.Component {
  constructor(props) {
    super(props);
  }

  render = () => {
    const { classes, match } = this.props;
    let langKey = "/";
    if (match.url.indexOf("/vi") !== -1) {
      langKey = "/vi/";
    }

    const menu = [
      {
        name: langKey === "/" ? "Service" : "Dịch Vụ",
        href: `${langKey}service`
      },
      {
        name: langKey === "/" ? "Career" : "Tuyển Dụng",
        href: `${langKey}career`
      },
      {
        name: langKey === "/" ? "Company" : "Công Ty",
        href: `${langKey}company`
      },
      {
        name: langKey === "/" ? "Contact" : "Liên Hệ",
        href: `${langKey}contact`
      },
      {
        name: langKey === "/" ? "President Message" : "Thông Điệp",
        href: `${langKey}president-message`
      }
    ];

    return (
      <div className={classes.root}>
        {/* Menu */}
        <AppBar position="static">
          <Toolbar>
            <Link
              to={`${langKey}`}
              style={{ textDecoration: "none", color: "#000" }}
            >
              <Button className={classes.areaLogo}>
                <LogoIcon className={classes.logo} />
                <Typography
                  variant="h5"
                  color="inherit"
                  style={{ marginLeft: 15 }}
                >
                  BPMS Viet Nam
                </Typography>
              </Button>
            </Link>

            <div className={classes.menu}>
              {menu.map(item => {
                return (
                  <Link
                    key={item.name}
                    to={item.href}
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    <Button color="inherit" className={classes.menuItem}>
                      <Typography variant="h6" color="inherit">
                        {item.name}
                      </Typography>
                    </Button>
                  </Link>
                );
              })}
            </div>

            <div className={classes.lang}>
              <Link to={`/vi${match.url.replace("/vi", "")}`}>
                <IconButton size="small" className={classes.langIcon}>
                  <Avatar
                    alt="vietnam"
                    src={vietnamImg}
                    className={classes.langImg}
                    classes={{ root: classes.langImgRoot }}
                  />
                </IconButton>
              </Link>

              <Link to={`${match.url.replace("/vi", "")}`}>
                <IconButton size="small" className={classes.langIcon}>
                  <Avatar
                    alt="japan"
                    src={japanImg}
                    className={classes.langImg}
                    classes={{ root: classes.langImgRoot }}
                  />
                </IconButton>
              </Link>
            </div>
          </Toolbar>
        </AppBar>
        {/* End Menu */}

        {/* Main Body */}
        <main className={classes.main}>{this.props.children}</main>
        {/* End Main Body */}
      </div>
    );
  };
}

MainLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
  match: PropTypes.object
};

export default withRouter(withStyles(styles)(MainLayout));
